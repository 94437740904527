import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable, ColumnDef, getFilteredRowModel, getPaginationRowModel, SortingState, getSortedRowModel, Row } from '@tanstack/react-table';
import { useVirtual } from 'react-virtual';
import cn from 'classnames';
import { TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faClipboardCheck, faClipboardQuestion } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { approveCandidateList, clearAddingQuestion, selectAddingQuestion, selectCategoriesList, selectQuestionsList } from '../../../store/qasSlice';
import { QAS } from '../../../constants/accessRights';
import { colorPrimary } from '../../../constants/colors';
import Filter from './Filter/Filter';
import TableCheckbox from '../TableCheckbox/TableCheckbox';
import CustomFooter from './CustomFooter/CustomFooter';
import FormAddingQuestion from '../../Forms/FormAddingQuestion/FormAddingQuestion';
import AlertDialog from '../../AlertDialog/AlertDialog';
import Notification from '../../Notification/Notification';
import { CandidateRow } from '../../../types/tableTypes';
import styles from './Candidates.module.scss';

const Candidates = (): JSX.Element => {
	const [data, setData] = useState<CandidateRow[]>([]); // кандидаты, преобразованные для работы в таблице
	const [sorting, setSorting] = useState<SortingState>([]); // сортированные кандидаты
	const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({}); // список выделенных строк 
	// const [showDoubles, setShowDoubles] = useState<boolean>(false); // флаг поиска дубликатов?
	const tableContainerRef = useRef<HTMLDivElement>(null); // ссылка на контейнер таблицы
	const tBodyRef = useRef<HTMLTableSectionElement>(null); // ссылка на body таблицы
	const [showFormAddingQuestion, setShowFormAddingQuestion] = useState<boolean>(false); // показ формы создания вопроса
	const [candidateData, setCandidateData] = useState<{ id: string, candidate: string }>({ id: '', candidate: '' }); // данные кандидата для манипуляций
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления создания вопроса
	const [showAlertDialogApproveCandidate, setShowAlertDialogApproveCandidate] = useState<boolean>(false); // показ диалогового окна для утверждения кандидата

	const dispatch = useAppDispatch();
	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// ставим слушатель на нажатие клавиш
	useEffect(() => {
		const keyDownHandler = (e: KeyboardEvent) => {
			if (e.code === 'PageDown') {
				e.preventDefault();
				table.getCanNextPage() && table.nextPage();
			} else if (e.code === 'PageUp') {
				e.preventDefault();
				table.getCanPreviousPage() && table.previousPage();
			}
		};

		document.addEventListener('keydown', keyDownHandler);

		// по уходу со страницы - убираем слушатель
		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, []);

	// следим за списком вопросов
	useEffect(() => {
		const candidateRowsData: CandidateRow[] = [];
		questionsList.data.forEach(questionItem => {
			if (questionItem.candidates.length > 0) {
				candidateRowsData.push(...questionItem.candidates.map(candidate => {
					return {
						candidate,
						question: questionItem.question,
						category: questionItem.category ? categoriesList.dictionary[questionItem.category] : `-${questionItem.category}`,
						questionId: questionItem.id,
					};
				}));
			}
		}); // преобразуем в формат таблицы
		setData(candidateRowsData);
		table.resetRowSelection(); // очищаем выделенные строки
	}, [questionsList.data]);

	const defaultColumn: Partial<ColumnDef<CandidateRow>> = {
		// Give our default column cell renderer editing superpowers!
		cell: ({ getValue, row, column: { id } }) => {
			/* eslint-disable */ // от безысходности
			const inputRef = useRef<HTMLInputElement>(null); // ссылка на input
			const initialValue = getValue(); // начальное значение ячейки
			// We need to keep and update the state of the cell normally
			const [value, setValue] = useState(initialValue as string); // значение ячейки
			const [extendedCell, setExtendedCell] = useState<boolean>(false); // флаг расширенной ячейки

			// If the initialValue is changed external, sync it up with our state
			useEffect(() => {
				/* Array.isArray(initialValue) ? setPhrases(initialValue) :  */setValue(initialValue as string);
			}, [initialValue]);

			// обработчик нажатия клавиши
			const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement | HTMLSelectElement>): void => {
				// если нажата клавиша Enter - уводим фокус с ячейки
				if (e.code === 'Enter') {
					inputRef.current?.blur();
				}
				// если нажата клавиша Escape - возвращаем начальное состояние ячейки
				else if (e.code === 'Escape') {
					// id === 'mark' && setValue(row.original.mark);
					// id === 'phrase' && setPhrases(row.original.phrase);
					// id === 'threshold' && setValue(row.original.threshold.toString());
					setTimeout(() => inputRef.current?.blur()); // уводим фокус с ячейки
				}
				// если нажата клавиша Ctrl для навигации
				if (e.ctrlKey) {
					const columnIdx = id === 'category' ? 3 : id === 'question' ? 2 : 1; // индекс колонки
					if (e.code === 'ArrowDown') {
						tBodyRef.current &&
							(Array.from(tBodyRef.current.children)
								.find(tr => tr.getAttribute('data-row-id') === String(+row.id + 1))?.children[columnIdx]?.children[0] as (HTMLInputElement | HTMLSelectElement))?.focus();
					} else if (e.code === 'ArrowUp') {
						tBodyRef.current &&
							(Array.from(tBodyRef.current.children)
								.find((tr, idx) => {
									// поднимаем скролл на высоту строки, т.к. строка подлезает под липкий header
									tr.getAttribute('data-row-id') === String(+row.id - 1) && (idx < 3) && tableContainerRef.current?.scrollBy(0, -18);
									return tr.getAttribute('data-row-id') === String(+row.id - 1);
								})?.children[columnIdx]?.children[0] as (HTMLInputElement | HTMLSelectElement))?.focus();
					} else if (e.code === 'ArrowLeft') {
						tBodyRef.current &&
							(Array.from(tBodyRef.current.children)
								.find(tr => tr.getAttribute('data-row-id') === row.id)?.children[columnIdx - 1 || 1]?.children[0] as (HTMLInputElement | HTMLSelectElement))?.focus();
					} else if (e.code === 'ArrowRight') {
						tBodyRef.current &&
							(Array.from(tBodyRef.current.children)
								.find(tr => tr.getAttribute('data-row-id') === row.id)?.children[columnIdx + 1]?.children[0] as (HTMLInputElement | HTMLSelectElement))?.focus();
					}
				}
			};

			return !extendedCell ?
				// поле для отображения
				<input
					className={styles.cellInput}
					value={String(value)}
					onChange={e => setValue(e.target.value)}
					onFocus={() => setExtendedCell(true)}
				// disabled={!(isAccess(QAS.QUESTION_APPROVE))}
				/>
				:
				// поле для манипуляций
				<TextField
					inputRef={inputRef}
					autoFocus
					multiline
					maxRows={5}
					size="small"
					variant="outlined"
					value={value}
					// onChange={e => setValue(e.target.value)}
					// disabled
					onBlur={() => {
						setExtendedCell(false);
					}}
					onKeyDown={(e) => keyDownHandler(e)}
					InputProps={{
						onFocus: (e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length), // курсор в конец строки
						style: {
							fontSize: 11,
							color: colorPrimary,
						},
					}}
					sx={{
						width: '100%',
						".MuiOutlinedInput-root": { padding: '0 0 0 3px' },
						".Mui-focused .MuiOutlinedInput-notchedOutline": { border: `1px solid ${colorPrimary}!important`, borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0.07)' },
					}}
				/>
				;
		},
	};

	const columns = useMemo<ColumnDef<CandidateRow, string>[]>(() => [
		{
			id: 'select',
			header: ({ table }) => (
				<TableCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
						disabled: !(isAccess(QAS.QUESTION_APPROVE)),
						tabIndex: -1,
						className: styles.checkbox,
					}}
				/>
			),
			cell: ({ row }) => (
				<TableCheckbox
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
						tabIndex: -1,
						className: styles.checkbox,
					}}
				/>
			),
			minSize: 55,
			maxSize: 55,
			size: 55,
		},
		{
			id: 'candidate',
			header: translate('candidateTable_columnTitleCandidate'),
			accessorKey: 'candidate',
			size: ((tableContainerRef.current?.clientWidth || 400) - 55 - 200 - 7) / 2,
			filterFn: 'myCustomFilter',
		},
		{
			id: 'question',
			header: translate('candidateTable_columnTitleQuestion'),
			accessorKey: 'question',
			size: ((tableContainerRef.current?.clientWidth || 400) - 55 - 200 - 7) / 2,
			filterFn: 'myCustomFilter',
		},
		{
			id: 'category',
			header: translate('candidateTable_columnTitleCategory'),
			accessorKey: 'category',
			size: 200,
			filterFn: 'myCustomFilter',
		}
	], [tableContainerRef.current?.clientWidth]);

	const table = useReactTable({
		data,
		columns,
		initialState: {
			pagination: {
				pageIndex: 0,
				pageSize: 100,
			}
		},
		state: {
			rowSelection,
			sorting,
		},
		filterFns: {
			// кастомный фильтр
			myCustomFilter: (rows, columnId, filterValue: string) => {
				if (columnId === 'candidate') return rows.original.candidate.toLowerCase().includes(filterValue.toLowerCase());
				else if (columnId === 'question') return rows.original.question.toLowerCase().includes(filterValue.toLowerCase());
				else if (columnId === 'category') {
					if (filterValue === translate('candidateTable_notAssignedTitle')) return rows.original.category.toLowerCase().includes('-'); // для не присвоенных к категориям вопросов
					else return rows.original.category.toLowerCase().includes(filterValue.toLowerCase());
				}
			},
		},
		defaultColumn, // для редактирования
		enableRowSelection: isAccess(QAS.QUESTION_APPROVE), // checkbox
		enableColumnResizing: true, // изменение ширины столбца
		onRowSelectionChange: setRowSelection, // checkbox
		onSortingChange: setSorting, // для сортировки
		getSortedRowModel: getSortedRowModel(), // для сортировки
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(), // для фильтрации
		getPaginationRowModel: getPaginationRowModel(), // для нумерации страниц
		columnResizeMode: 'onChange', // изменение ширины столбца в runtime
	});

	// для разового вычисления высоты строки
	const estimateSize = useCallback(() => 18, []);
	// виртуализация строк
	const { rows } = table.getRowModel();
	const rowVirtualizer = useVirtual({ parentRef: tableContainerRef, size: rows.length, estimateSize });
	const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
	const paddingTop = virtualRows?.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
	const paddingBottom = virtualRows?.length > 0 ? totalSize - (virtualRows?.[virtualRows?.length - 1]?.end || 0) : 0;

	// обработчик утверждения кандидата
	const approveCandidateHandler = (): void => {
		setShowAlertDialogApproveCandidate(false); // закрываем диалоговое окно
		dispatch(approveCandidateList({
			destinationQuestionId: candidateData.id,
			candidateList: [{ id: candidateData.id, candidate: candidateData.candidate }],
		}));
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.container} ref={tableContainerRef}>
				<table className={styles.table} style={{ width: table.getCenterTotalSize() }}>
					<thead className={cn(styles.thead, {
						[styles.theadBoxShadow]: tableContainerRef.current && tableContainerRef.current.scrollTop > 0, // тень из под шапки и размытие под ним, когда скроллим таблицу
					})}>
						{table.getHeaderGroups().map(headerGroup => (
							<tr className={styles.tr} key={headerGroup.id}>
								{headerGroup.headers.map(header => (
									<th className={styles.th} {...{
										key: header.id,
										colSpan: header.colSpan,
										style: { width: header.getSize() }
									}}>
										{!header.isPlaceholder &&
											<>
												<div onClick={header.column.getToggleSortingHandler()} className={header.id === 'select' ? styles.thTitleSelect : styles.thTitle}>
													{flexRender(header.column.columnDef.header, header.getContext())}
													{{ asc: <FontAwesomeIcon icon={faArrowUp} />, desc: <FontAwesomeIcon icon={faArrowDown} /> }[header.column.getIsSorted() as string] ?? null}
												</div>
												{header.column.getCanFilter() &&
													<Filter table={table} column={header.column} rowSelection={rowSelection} />
												}
											</>
										}
										{header.id !== 'select' &&
											<div {...{
												onMouseDown: header.getResizeHandler(),
												onTouchStart: header.getResizeHandler(),
												className: styles.resizerWrapper,
											}}>
												<div className={styles.resizerDelimiter}></div>
											</div>
										}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className={styles.tbody} ref={tBodyRef}>

						{paddingTop > 0 && (
							<tr>
								<td style={{ height: paddingTop }} />
							</tr>
						)}

						{virtualRows.map(virtualRow => {
							const row = rows[virtualRow.index] as Row<CandidateRow>
							return (
								<tr className={cn(styles.tr, { [styles.trSelected]: table.getSelectedRowModel().flatRows.includes(row) })} key={row.id} data-row-id={row.id}>
									{row.getVisibleCells().map(cell => (
										<td className={cn(styles.td, {
											[styles.tdSelect]: cell.column.id === 'select'
										})} title={cell.getValue() !== undefined ? String(cell.getValue()) : ''} {...{
											key: cell.id,
											style: {
												width: cell.column.getSize()
											},
										}}>
											{cell.column.id === 'select' &&
												<div className={styles.tdButtons}>
													{isAccess([QAS.QUESTION_ADD, QAS.QUESTION_APPROVE]) &&
														<button
															onClick={() => {
																setCandidateData({ candidate: row.original.candidate, id: row.original.questionId }); // пишем id и название кандидата
																setShowFormAddingQuestion(true); // открываем форму
															}}
														>
															<FontAwesomeIcon
																icon={faClipboardQuestion}
																size='lg'
																title={translate('candidateTable_makeMainQuestionTitle')}
																style={{ cursor: 'pointer' }}
															/>
														</button>
													}
													{isAccess(QAS.QUESTION_APPROVE) &&
														<button
															onClick={() => {
																setCandidateData({ candidate: row.original.candidate, id: row.original.questionId }); // пишем id и название кандидата
																setShowAlertDialogApproveCandidate(true); // открываем подтверждение
															}}
														>
															<FontAwesomeIcon
																icon={faClipboardCheck}
																size='lg'
																title={translate('candidateTable_approveCandidateTitle')}
																style={{ cursor: 'pointer' }}
															/>
														</button>
													}
												</div>
											}
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									))}
								</tr>
							)
						})}

						{paddingBottom > 0 && (
							<tr>
								<td style={{ height: paddingBottom }} />
							</tr>
						)}

					</tbody>
				</table>
				{table.getRowModel().rows.length === 0 && <div className={styles.notFound}>{translate('notFound')}</div>
				}
			</div>
			<CustomFooter table={table} rowSelection={rowSelection} tableContainerRef={tableContainerRef} data={data} />

			{/* форма создания основного вопроса из кандидата */}
			{showFormAddingQuestion &&
				<FormAddingQuestion
					showModal={showFormAddingQuestion}
					setShowModal={setShowFormAddingQuestion}
					setShowNotification={setShowNotification}
					candidate={candidateData.candidate}
					candidateId={candidateData.id}
				/>
			}
			{/* уведомление о создании основного вопроса */}
			{showNotification &&
				<Notification
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					selectDataResponse={selectAddingQuestion}
					clearDataResponse={clearAddingQuestion}
					titleFailed={translate('noticeAddQuestion_failed')}
					titleSuccess={translate('noticeAddQuestion_success')}
				/>
			}
			{/* подтверждение утверждения кандидата */}
			{showAlertDialogApproveCandidate &&
				<AlertDialog
					showAlertDialog={showAlertDialogApproveCandidate}
					setShowAlertDialog={setShowAlertDialogApproveCandidate}
					submitHandler={approveCandidateHandler}
					title='dialog_approveCandidateTitle'
					description='dialog_approveCandidateConfirm'
					name={candidateData.candidate}
				/>
			}
		</div>
	);
};

export default Candidates;
